import * as React from 'react';
import {useEffect} from 'react';
import {
  PAYBACK_CODE_COOKIE,
  PAYBACK_STATE_COOKIE,
  ROUTE_PAYBACK_ACCOUNTBINDING,
} from '../../../common/constants';
import {navigate} from 'gatsby';
import * as Cookies from 'js-cookie';
import {
  B2CComponentsProvider,
  LoadingAnimationWrapper,
} from '@edekadigital/b2c-components';
import {AccountbindingDispatcher} from '../../../components/app/payback/AccountbindingDispatcher';
import Accountbinding from './accountbinding';

const AccountbindingEdeka: React.FC = () => {
  return <AccountbindingDispatcher />;
};
export default AccountbindingEdeka;
